<template>
  <div
    class="personnelarchies flex-col item-b"
    v-loading="showLoad"
    element-loading-text="拼命加载中"
  >
    <!-- 查询控制 -->
    <div class="header">
      <div class="search-box">
        <div>
          <el-date-picker
            class="custom-input"
            v-model="value3"
            value-format="yyyy"
            format="yyyy"
            type="year"
            
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
      </div>
      <div style="margin-left: 30px">
        <el-button
          class="btn item-primary"
          type="primary"
          @click="searchClick(value3)"
          >查询</el-button
        >
        <el-button class="btn item-info" type="info" @click="resetBtn(value3)"
          >重置</el-button
        >
      </div>
    </div>
  
    
    <div class="picbox">
      <div class="picleft">
        <div class="piclefttitle">报修次数</div>
        <div v-if="Repairtimes.length == 0" class="Processingtime">暂无数据</div>
        <Echart :option="ChartData3" ></Echart>
        
      </div>
      <div class="picleft">
        <div class="piclefttitle">维修次数</div>
        <div v-if="toiletsList.length == 0" class="Processingtime">暂无数据</div>
        <Echart :option="ChartData1"></Echart>
       
      </div>
    </div>
    <div class="pictop">
      <div class="pictoptitie">累计维修时间</div>
      <div v-if="repairTimeCount.length == 0" class="Processingtime">暂无数据</div>
      <Echart :option="ChartData2" ></Echart>
    </div>
  </div>
</template>
<script>
import axios from "@/request";
import Echart from "./Echart";
export default {
  name: "MaintenanceStatistical",
  components: {
    Echart,
  },
  data() {
    return {
      showLoad: false,
      searchText: "",
      value3: "2021", //查询日期
      loadingData: true,
      ChartData3: null,
      ChartData2: null,
      ChartData1:null,
      Repairtimes: "", // 报修次数
      toiletsList: "", // 公厕列表
      TimeCountX: [],
      repairTimeCount: '', // 维修时长
      current:'',
      pickerOptions: {
          disabledDate(time){
          return time.getTime() > Date.now() - 3600 * 1000 * 24;
        }
      },
      


    };
  },
  created(){
    
  },
  mounted() {
    this.getData(this.value3);
  },


  methods: {
    searchClick(val) {
      console.log(val)
      this.getData(val);
    },

    resetBtn(val) {
      this.value3 = "";
      this.getData(this.value3);
      
    },
    getData(year) {
      axios.get(`/toiletGuarantee/count?year=${year}`).then((res) => {
        console.log(res);
        this.Repairtimes = res.reportCount
        this.toiletsList = res.repairCount
        this.repairTimeCount = res.repairTimeCount
        
        // 报修次数
        let chartData3 = this.yuanLine(); 
        let reportCount = res.reportCount
        console.log(reportCount)
        let chart = []
        reportCount.forEach(item=> {
          chart.push({value :item.num, name:item.toiletName})
        })
        console.log(chart)
        chartData3.series[0].data = chart
        
        chartData3.legend.show = reportCount.length ==0 ? false:true
        console.log(chartData3)
        this.ChartData3 = chartData3;
        
        // 累计维修时间
        let x = [] 
        let data = []
        let ymax = null
        let repairTimeCount = res.repairTimeCount;
        repairTimeCount.forEach(element => {
          // this.TimeCountX.push(element.toiletName);
          // this.chartData2.xAxis.data.push(element.toiletName)
          x.push(element.toiletName)
          data.push(element.repairTime)
          if (ymax==null) {
            ymax = element.repairTime+50
          } else {
            if (element.repairTime>ymax){
              ymax = element.repairTime
            }

          }
        });
        let chartData2 = this.getChartData(); 
        chartData2.xAxis.data= x
        chartData2.series[0].data = data
        chartData2.yAxis.max = ymax
        this.ChartData2 = chartData2;

        // 维修次数
        let chartData1 = Object.assign({},this.yuanLine())   
        console.log(chartData1)
        let repairCount = res.repairCount
        console.log(repairCount)
        this.ChartData1 = chartData1
        let chart1 = []
        repairCount.forEach(item=> {
          chart1.push({value :item.num, name:item.toiletName})
        })
        chartData1.series[0].data = chart1
        chartData1.series[0].name = "维修次数"
        chartData1.legend.show = repairCount.length ==0 ? false:true 
        
      });
    },
    // 饼图
    yuanLine() {
      return {
        legend: {
          orient: "vertical",
          right: 100,
          // top: 10,
          height:200,
          show: true,
          formatter: function (name) {
            return name ;
          },
          type: 'scroll',            // 设置图例翻页
          // icon: 'rect',
          // itemWidth: 12,             // 图例图形宽度
          // itemHeight: 10,
          // orient: 'horizontal',     // 图例横向排布
          // data: title,              // title是一个列表，存着图例的数据
          // left: 15,				// 图例位置
          // bottom: 8,
          // textStyle: {
          //   color: '#fff',
          //   fontSize: 12
          // },
          // pageIconColor: '#fff',
          // pageTextStyle: {
          //   color: '#fff'
          // },
          itemGap: 20

        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          position: ["0%", "0%"],
        },
        color: [
          " #91CD76",
          "#76CDBC",
          "#FAC958",
          "#5077F3",
          "#EF6765",
          "#F2E87E",
          "#73BFDF",
          "rgb(38, 194, 85)",
          "#F98F39",
          "#C760F7",
          "#38F1C3",
          "#FF6AB0",
        ],
        toolbox: {
          show: false,
        },
        series: [
          {
            name: "报修次数",
            label: {
              //去除饼图的指示折线label
              normal: {
                show: false,
                position: "inside",
                formatter: "{b}:{d}%",
              },
            },
            type: "pie",
            radius: [40, 80],
            center: ["40%", "40%"],
            data: [
              
            ],
          },
        ],
      };
    },
    yuan() {
      return {
        legend: {
          orient: "vertical",
          right: 100,
          top: 10,
          formatter: function (name) {
            return name ;
          },
          // type: 'scroll',            // 设置图例翻页
          // icon: 'rect',
          // itemWidth: 12,             // 图例图形宽度
          // itemHeight: 10,
          // orient: 'horizontal',     // 图例横向排布
          // data: title,              // title是一个列表，存着图例的数据
          // left: 15,				// 图例位置
          // bottom: 8,
          // textStyle: {
          //   color: '#fff',
          //   fontSize: 12
          // },
          // pageIconColor: '#fff',
          // pageTextStyle: {
          //   color: '#fff'
          // },
          // itemGap: 20

        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          position: ["0%", "0%"],
        },
        color: [
          " #91CD76",
          "#76CDBC",
          "#FAC958",
          "#5077F3",
          "#EF6765",
          "#F2E87E",
          "#73BFDF",
          "rgb(38, 194, 85)",
          "#F98F39",
          "#C760F7",
          "#38F1C3",
          "#FF6AB0",
        ],
        toolbox: {
          show: true,
        },
        series: [
          {
            name: "",
            label: {
              //去除饼图的指示折线label
              normal: {
                show: false,
                position: "inside",
                formatter: "{b}:{d}%",
              },
            },
            type: "pie",
            radius: [40, 80],
            center: ["40%", "40%"],
            data: [
              
            ],
          },
        ],
      };
    },
    // 折线图
    getChartData() {
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 60,
          right: 35,
          // bottom: 35,
          top: 25,
        },
        xAxis: {
          name: "",
          nameTextStyle: {
            color: "#333",
            fontSize: 12,
          },
          type: "category",
          boundaryGap: true,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          axisTick: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: '30',
            margin: 10,
            top:25,
            textStyle: {
              // fontWeight:"bolder",
              color: "#000000",
              fontSize: '10px',
            },
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 660,
          axisLabel: {
            formatter: '{value} h'
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        series: [
          // {
          //   name: "男厕",
          //   type: "line",
          //   lineStyle: {
          //     color: "#2275FF",
          //     width: 3,
          //   },
          //   color: "#2275FF",
          //   data: [34, 90, 20, 20, 20, 12, 11, 14, 90, 21, 23, 46],
          //   itemStyle: { normal: { label: { show: true } } },
          // },
          {
            name: "维修时长",
            type: "line",
            lineStyle: {
              color: "#FF8395",
              width: 3,
            },
            color: "#FF8395",
            data: [
              10,
              12,
              11,
              14,
              9,
              30,
              10,
              20,
              32,
              11,
              34,
              90,
              20,
              20,
              20,
              12,
              11,
              14,
              90,
              23,
              21,
              23,
              46,
              24,
            ],
            itemStyle: { normal: { label: { show: true } } },
            // markLine: {
            //     label:{
            //       position:'middle',
            //       formatter:"5",
            //       fontSize:'16'
            //   },
            //     animation: false,
            //     data: [
            //         {yAxis: 5,}
            //     ],
            //     symbol: "none",
            //     silent: true,
            //     lineStyle: {
            //         type:"solid",
            //         width: 3,
            //         color: "#FF5D31"
            //     }
            // }
          },
        ],
        id: 1,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.personnelarchies {
  flex: auto;
  height: 100%;
  box-sizing: border-box;
  & ::v-deep .el-drawer__body {
    overflow: auto;
  }
}
.header {
  flex: none;
  display: flex;
  padding: 20px;
  .search-box {
    // width: 12rem;
    margin-left: 65px;
  }
}
.picbox {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.Processingtime {
  font-size: 20px;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.picleft {
  width: 40%;
  height: 300px;
  background-color: #ffffff;
  box-shadow: 0px 0px 92px 0px rgba(62, 136, 255, 0.11);
  .piclefttitle {
    font-size: 18px;
    font-weight: bold;
    padding: 15px 25px;
  }
}
.pictop {
  margin: 20px auto;
  width: 90%;
  height: 365px;
  background: #ffffff;
  // box-shadow: 0px 0px 76px 0px rgba(63, 137, 255, 0.1);
  .pictoptitie {
    font-size: 18px;
    font-weight: bold;
    padding: 15px 25px;
  }
}
</style>